//byc moze potrzebne beda poprawki
//aktualnie id uzytkownika jest zapisywane po udanej rejestracji do localStorage
//https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API#web_storage_interfaces
//i potem jest z niego odczytywana na stronie z weryfikacja
//magazyn ten dziala lokalnie, w pamieci przegladarki (nie sesja!)
//musimy przemyslec jak ten system ma dzialac koncowo,
//i czy wrzucanie do pamieci przegladarki id uzytkownika to taki dobry pomysl

import { useState } from 'react'
import myFetch from '../../utils/myFetch'
import { useNavigate } from 'react-router-dom'
import Navbar from '../../utils/Navbar/Navbar'
import myAlert from '../../utils/AlertProvider'

import './verify.scss'
import { Button, TextField, useTheme } from '@mui/material'

const Verify = () => {
    const [code, setCode] = useState('')
    const [inputError, setInputError] = useState(false)
    const userid = localStorage.getItem('verificationUserID')
    const [alertPlaceholder, setAlertPlaceholder] = useState(null)
    const navigate = useNavigate()

    const theme = useTheme()

    const verify = async () => {
        await myFetch('/api/verify', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                userid: userid,
                code: code,
            }),
        })
            .then(res => res.json())
            .then(res => {
                if (res.status == 0) {
                    setAlertPlaceholder(
                        myAlert(
                            'Zweryfikowano',
                            '',
                            'Kontynuuj',
                            () => {
                                setAlertPlaceholder(null)
                                localStorage.removeItem('verificationUserID')
                                localStorage.setItem('loggedUserID', userid)
                                navigate('/')
                            },
                            'success'
                        )
                    )
                } else if (res.status == 1) {
                    setAlertPlaceholder(
                        myAlert(
                            'Błędny kod',
                            'Spróbuj ponownie',
                            'Zamknij',
                            () => {
                                setAlertPlaceholder(null)
                            },
                            'error'
                        )
                    )
                    setInputError(true)
                } else {
                    alert('Wystąpił problem')
                }
            })
            .catch(err => console.error(err))
    }

    const sendAgain = () => {
        setAlertPlaceholder(
            myAlert(
                'Err',
                'Not implemented',
                'Zamknij',
                () => {
                    setAlertPlaceholder(null)
                },
                'error'
            )
        )
    }

    return (
        <>
            <Navbar />
            <div className='verifyMain' style={{ backgroundColor: theme.palette.background.default, color: theme.palette.font.color }}>
                <header>
                    <h1 style={{ color: theme.palette.primary.main }}>Weryfikacja</h1>
                </header>
                <p>
                    Na podany przy rejestracji adres e-mail wysłaliśmy kod weryfikacyjny <br></br> Wprowadź go poniżej
                </p>
                <section>
                    <TextField error={inputError} onChange={e => setCode(e.target.value)} maxLength={6} type='text' label='6-cio cyfrowy kod weryfikacyjny...' />
                    <Button variant='contained' onClick={verify}>
                        Zweryfikuj
                    </Button>
                    <Button onClick={sendAgain}>Wyślij ponownie</Button>
                </section>
            </div>
            {alertPlaceholder}
        </>
    )
}

export default Verify
