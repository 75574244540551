import { useTheme } from '@mui/material'

import './openSet.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import myFetch from '../../utils/myFetch'
import { Button, FormControlLabel, IconButton, MenuItem, Select, Switch, TextField, Tooltip } from '@mui/material'
import myAlert from '../../utils/AlertProvider'
import VerifiedIcon from '@mui/icons-material/Verified'
import Navbar from '../../utils/Navbar/Navbar'

const OpenSet = () => {
    const theme = useTheme()
    const navigate = useNavigate()

    let { setID } = useParams()

    const [setName, setSetName] = useState('')
    const [setAuthor, setSetAuthor] = useState('')
    const [authorID, setAuthorID] = useState('')
    const [listFiszki, setListFiszki] = useState()
    const [arrayFiszki, setArrayFiszki] = useState([])
    const [setIcon, setSetIcon] = useState(require('../../assets/images/fishki_nobg_sm.png'))
    const [authorImage, setAuthorImage] = useState(`https://localhost:3001/profile_pictures/user.png`)
    const [category, setCategory] = useState('Inne')
    const [alertPlaceholder, setAlertPlaceholder] = useState(null)
    const [isByAdmins, setIsByAdmins] = useState(false)

    useEffect(() => {
        getSetData()
    }, [])

    const generateList = temp => {
        setListFiszki(
            temp.map((element, i) => (
                <li>
                    <span>{element.side1}</span>
                    <span>{element.side2}</span>
                </li>
            ))
        )
    }

    const getSetData = async () => {
        await myFetch('/api/getSetData', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ setID: setID }),
        })
            .then(res => res.json())
            .then(res => {
                setSetName(res.name)
                setSetAuthor(res.author.nick)
                setAuthorID(res.author.id)
                setArrayFiszki(res.fiszki)
                setIsByAdmins(res.isByAdmins)
                generateList(res.fiszki)
                if (res.author.photoPath.length > 0) {
                    setAuthorImage(`https://localhost:3001/${res.author.photoPath}`)
                }

                if (res.category == 1) {
                    setSetIcon(require('../../assets/images/english.png'))
                    setCategory('Angielski')
                } else if (res.category == 2) {
                    setSetIcon(require('../../assets/images/german.png'))
                    setCategory('Niemiecki')
                } else {
                    setSetIcon(require('../../assets/images/fishki_nobg_sm.png'))
                    setCategory('Inne')
                }
            })
    }

    const toAuthorProfile = () => {
        navigate('/profile/' + authorID)
    }

    const browseFishki = () => {
        navigate('/browseSet/' + setID)
    }

    const toWriting = () => {
        navigate('/write/' + setID)
    }

    const writeTest = async () => {
        navigate('/test/' + setID)
    }

    return (
        <>
            <Navbar />
            <div style={{ backgroundColor: theme.palette.background.default, color: theme.palette.font.color }} className='mainOpenSet'>
                <header>
                    {setName}{' '}
                    {isByAdmins ? (
                        <Tooltip title='Zestaw tworzony przed administratorów'>
                            <VerifiedIcon fontSize='large' color='secondary' />
                        </Tooltip>
                    ) : null}
                </header>
                <div className='horizontalContainer'>
                    <aside>
                        <header>Fiszki w tym zestawie</header>
                        <ul>{listFiszki}</ul>
                    </aside>
                    <div className='verticalContainer'>
                        <section className='gora'>
                            <img src={setIcon} />
                            <div className='verticalBox'>
                                <img className='photoAuthor' src={authorImage} />
                                <p>
                                    Autor:{' '}
                                    <span onClick={toAuthorProfile} style={{ color: theme.palette.primary.light }}>
                                        {setAuthor}
                                    </span>
                                </p>
                                <p>Kategoria: {category}</p>
                                <p>Liczba fiszek w zestawie: {arrayFiszki.length}</p>
                            </div>
                        </section>
                        <section className='dol'>
                            <Button onClick={browseFishki} variant='contained'>
                                Przeglądaj fiszki
                            </Button>
                            <Button onClick={toWriting} color='secondary' variant='contained'>
                                Ćwicz pisanie
                            </Button>
                            <Button onClick={writeTest} color='secondary' variant='contained'>
                                Wykonaj test
                            </Button>
                            <Button disabled variant='contained'>
                                1 vs 1 (wkrótce)
                            </Button>
                        </section>
                    </div>
                </div>
                {alertPlaceholder}
            </div>
        </>
    )
}

export default OpenSet
