import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import FacebookLogin from '@greatsumini/react-facebook-login'
import GoogleAuth from '../../utils/GoogleAuth'
import myFetch from '../../utils/myFetch'
import myAlert from '../../utils/AlertProvider'

import Button from '@mui/material/Button'
import { TextField, Backdrop, CircularProgress } from '@mui/material'

import { Facebook } from '@mui/icons-material'

//system logowania, aktualnie dziala tylko logowanie z fb i google
//ale i tak bez sesji ani nic takiego

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)

    const [alertPlaceholder, setAlertPlaceHolder] = useState(null)

    const navigate = useNavigate()

    const googleCallback = async googleData => {
        await fbOrGoogle(true, false, googleData.sub, googleData.email, googleData.name)
    }

    const facebookCallback = async facebookData => {
        await fbOrGoogle(false, true, facebookData.id, facebookData.email, facebookData.name)
    }

    const login = async () => {
        setLoading(true)

        //reqex wziety z neta jak cos
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        if (!email.match(validRegex)) {
            setLoading(false)
            setAlertPlaceHolder(
                myAlert(
                    'Wystąpił błąd',
                    'Wprowadź poprawny adres e-mail',
                    'Zamknij',
                    () => {
                        setAlertPlaceHolder(null)
                    },
                    'error'
                )
            )
            return
        }

        if (password.length < 5) {
            setLoading(false)
            setAlertPlaceHolder(
                myAlert(
                    'Wystąpił błąd',
                    'Wprowadź hasło',
                    'Zamknij',
                    () => {
                        setAlertPlaceHolder(null)
                    },
                    'error'
                )
            )
            return
        }

        await myFetch('/api/login', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        })
            .then(res => res.json())
            .then(res => {
                setLoading(false)
                if (res.status == 0) {
                    localStorage.setItem('loggedUserID', res.userid)
                    navigate('/')
                } else if (res.status == 2) {
                    localStorage.setItem('verificationUserID', res.userid) //przechowanie
                    setAlertPlaceHolder(
                        myAlert('Uwaga', 'Wymagana weryfikacja adresu e-mail', 'Zweryfikuj', () => modalClose())
                    )
                } else
                    setAlertPlaceHolder(
                        myAlert('Wystąpił błąd', res.error, 'Zamknij', () => setAlertPlaceHolder(null), 'error')
                    )
            })
    }

    const modalClose = () => {
        setAlertPlaceHolder(null)
        navigate('/verify/')
    }

    const fbOrGoogle = async (isUsingGoogle, isUsingFacebook, outerID, email, nick) => {
        await myFetch('/api/fbOrGoogle', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: email,
                nick: nick,
                isUsingGoogle: isUsingGoogle,
                isUsingFacebook: isUsingFacebook,
                outerID: outerID,
            }),
        })
            .then(res => res.json())
            .then(res => {
                if (res.status === 0) {
                    navigate('/')
                    localStorage.setItem('loggedUserID', res.userid)
                } else
                    setAlertPlaceHolder(
                        myAlert('Wystąpił błąd', res.error, 'Zamknij', () => setAlertPlaceHolder(null), 'error')
                    )
            })
    }

    return (
        <form>
            <h2>Logowanie</h2>
            <fieldset>
                <TextField
                    type='email'
                    label='Adres e-mail'
                    variant='outlined'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <TextField
                    type='password'
                    label='Hasło'
                    variant='outlined'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <Button color='primary' variant='contained' onClick={login}>
                    Zaloguj się
                </Button>
                <div className='button-group'>
                    <FacebookLogin
                        appId='754652656585762'
                        onSuccess={response => console.log('Login Success!', response)}
                        onFail={error => console.log('Login Failed!', error)}
                        onProfileSuccess={response => {
                            console.log('Get Profile Success!', response)
                            facebookCallback(response)
                        }}
                        render={({ onClick, logout }) => (
                            <Button variant='contained' color='facebook' onClick={onClick} startIcon={<Facebook />}>
                                Facebook
                            </Button>
                        )}
                    />
                    <GoogleAuth callback={googleCallback} />
                </div>
            </fieldset>
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress color='inherit' />
            </Backdrop>
            {alertPlaceholder}
        </form>
    )
}

export default Login
