import { Modal, Button } from '@mui/material'
import React, { useState } from 'react'

/**
 *
 * @param {String} title tekst na headerze
 * @param {String} text tekst ten maly pod headerem
 * @param {String} buttonText tekst na przycisku
 * @param {Function} onClose funkcja zamknięcia (pamiętaj zawrzeć ustawienie placeholder'a na null)
 * @param {String} type typ - od niego zaleza kolory: info - zwykly, error - czerwony, warning - żółty, success - zielony, custom - patrz niżej
 * @param {Object} customColors pozwala ustawić własne kolory dla ramki i tekstu na headerz'e
 * @returns {ReactJSXElement} zwraca otwarty od razu alert
 */

//jak uzywac? Przyklad nizej

const MyAlertExample = () => {
    const [alertPlaceholder, setAlertPlaceholder] = useState(null)

    const openAlert = () => {
        setAlertPlaceholder(
            myAlert(
                'Lorem ipsum',
                'Ala ma kota, kot ma ale, ona go kochala, a on ja wcale',
                'Super',
                closeAlert,
                'success'
            )
        )
    }

    const closeAlert = () => {
        setAlertPlaceholder(null)
        //dalsze akcje po zamknieciu przycisku, jakies navigate czy inne pierdoly
    }

    return (
        <div>
            <button onClick={openAlert}>Otworz alert</button>
            {alertPlaceholder}
        </div>
    )
}

const myAlert = (title, text, buttonText, onClose, type = 'info', customColors = {}) => {
    let BorderColor = 'rgb(32, 32, 32)'
    let TextColor = 'rgb(220, 220, 220)'
    if (type == 'error') {
        BorderColor = 'rgb(200, 0, 0)'
        TextColor = 'rgb(180, 0, 0)'
    } else if (type == 'warning') {
        BorderColor = 'rgb(240, 200, 30)'
        TextColor = 'rgb(240, 200, 30)'
    } else if (type == 'success') {
        BorderColor = 'rgb(0, 200, 0)'
        TextColor = 'rgb(0, 200, 0)'
    } else if (type == 'custom') {
        BorderColor = customColors.border
        TextColor = customColors.header
    }

    return (
        <Modal className='modal' open>
            <div style={{ borderColor: BorderColor }} className='modalContent'>
                <h1 style={{ color: TextColor }}>{title}</h1>
                <p>{text}</p>
                <Button
                    onClick={() => {
                        onClose()
                    }}
                    variant='contained'>
                    {buttonText}
                </Button>
            </div>
        </Modal>
    )
}

export default myAlert
