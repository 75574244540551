import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import myFetch from '../../utils/myFetch'

import Button from '@mui/material/Button'
import { TextField, Backdrop, CircularProgress } from '@mui/material'

import myAlert from '../../utils/AlertProvider'

const Register = () => {
    const [nick, setNick] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const [alertPlaceholder, setAlertPlaceHolder] = useState(null)

    const register = async () => {
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

        if (!email.match(validRegex)) {
            setAlertPlaceHolder(
                myAlert(
                    'Wystąpił błąd',
                    'Wprowadź poprawny adres e-mail',
                    'Zamknij',
                    () => {
                        setAlertPlaceHolder(null)
                    },
                    'error'
                )
            )
            return
        }
        const regexHasla = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        if (!password.match(regexHasla)) {
            setAlertPlaceHolder(
                myAlert(
                    'Wystąpił błąd',
                    'Długość hasła musi wynosić conajmniej 8 znaków, w tym wielka i mała litera, cyfra i znak specjalny',
                    'Zamknij',
                    () => {
                        setAlertPlaceHolder(null)
                    },
                    'error'
                )
            )
            return
        }

        //dodac sprawdzanie złozonosci hasla i poprawnego emaila i inne tam takie
        setLoading(true)
        await myFetch('/api/register', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: email,
                password: password,
                nick: nick,
            }),
        })
            .then(res => res.json())
            .then(res => {
                setLoading(false)
                if (res.status === 0) {
                    if (!res.verifyEmail) navigate('/')
                    localStorage.setItem('verificationUserID', res.userid) //przechowanie
                    setAlertPlaceHolder(
                        myAlert('Uwaga', 'Wymagana weryfikacja adresu e-mail', 'Zweryfikuj', () => modalClose())
                    )

                    console.log(1)
                } else
                    setAlertPlaceHolder(
                        myAlert('Wystąpił błąd', res.error, 'Zamknij', () => setAlertPlaceHolder(null), 'error')
                    )
            })
    }

    const modalClose = () => {
        setAlertPlaceHolder(null)
        navigate('/verify/')
    }

    return (
        <form>
            <h2>Rejestracja</h2>
            <fieldset>
                <TextField
                    type='text'
                    label='Nazwa użytkownika'
                    variant='outlined'
                    value={nick}
                    onChange={e => setNick(e.target.value)}
                />
                <TextField
                    type='email'
                    label='Adres e-mail'
                    variant='outlined'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <TextField
                    type='password'
                    label='Hasło'
                    variant='outlined'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <Button color='primary' variant='contained' onClick={register}>
                    Zarejestruj się!
                </Button>
            </fieldset>
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress color='inherit' />
            </Backdrop>
            {alertPlaceholder}
        </form>
    )
}

export default Register
