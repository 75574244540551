import { Button, IconButton, useTheme } from '@mui/material'
import './home.scss'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import myAlert from '../../utils/AlertProvider'
import myFetch from '../../utils/myFetch'

import LogoutIcon from '@mui/icons-material/Logout'
import LoginIcon from '@mui/icons-material/Login'

import NewSetsSection from './NewSetsSection'
import LastSetsSection from './LastSetsSection'
import RecomendedSection from './recomendedSets'
import ItIsBeta from './ItIsBeta'

import Navbar from '../../utils/Navbar/Navbar'

const Home = () => {
    const userid = localStorage.getItem('loggedUserID')
    const navigate = useNavigate()
    const theme = useTheme()

    const [nick, setNick] = useState('')

    useEffect(() => {
        if (userid != null) {
            getUserData()
        }
    }, [userid])

    const getUserData = async () => {
        await myFetch('/api/getBasicUserData', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                userid: userid,
            }),
        })
            .then(res => res.json())
            .then(res => {
                setNick(res.nick)
            })
            .catch(err => {
                setAlertPlaceholder(
                    myAlert(
                        'Wystąpił błąd',
                        'Błąd połączenia z serwerem',
                        'Zamknij',
                        () => {
                            setAlertPlaceholder(null)
                        },
                        'error'
                    )
                )
            })
    }

    const [alertPlaceholder, setAlertPlaceholder] = useState(null)

    const toNewSet = () => {
        if (userid != null) {
            navigate('/newSet/')
        } else {
            setAlertPlaceholder(
                myAlert(
                    'Uwaga',
                    'Musisz się zalogować, żeby móc dodawać zestawy fiszek',
                    'Zamknij',
                    () => {
                        setAlertPlaceholder(null)
                    },
                    'error'
                )
            )
        }
    }

    const logout = () => {
        localStorage.removeItem('loggedUserID')
        navigate('/')
    }

    const login = () => {
        navigate('/login/')
    }

    return (
        <>
            <Navbar />
            <main className='homeMain' style={{ backgroundColor: theme.palette.background.default, color: theme.palette.font.color }}>
                <section className='homeContainer'>
                    <ItIsBeta setAlertPlaceholder={setAlertPlaceholder} />
                    <NewSetsSection setAlertPlaceholder={setAlertPlaceholder} />
                    <RecomendedSection setAlertPlaceholder={setAlertPlaceholder} />
                    <LastSetsSection setAlertPlaceholder={setAlertPlaceholder} />
                </section>
                {alertPlaceholder}
            </main>
        </>
    )
}

/*

<header className='appBar'>
                    <section>
                        {userid == null ? (
                            <p className='userNameAppBar'>Niezalogowany</p>
                        ) : (
                            <p className='userNameAppBar'>
                                Witaj, <span style={{ color: theme.palette.primary.main }}> {nick} </span>
                            </p>
                        )}
                    </section>
                    <aside>
                        {userid != null ? (
                            <Button variant='contained' onClick={logout}>
                                Wyloguj
                            </Button>
                        ) : (
                            //<IconButton style={{ backgroundColor: theme.palette.primary.main }} onClick={logout}>
                            //<LogoutIcon />
                            //</IconButton>
                            <Button variant='contained' onClick={login}>
                                Zaloguj
                            </Button>
                        )}
                        <Button variant='contained' onClick={toNewSet}>
                            Nowy zestaw fiszek
                        </Button>
                    </aside>
                </header>*/

export default Home
