import myAlert from '../utils/AlertProvider'
import myFetch from '../utils/myFetch'

import { useState, useEffect } from 'react'

const useUserData = userid => {
    const [userData, setUserData] = useState()
    const [alertPlaceholder, setAlertPlaceholder] = useState(null)

    useEffect(() => {
        if (userid == null) {
            setUserData(null)
            setAlertPlaceholder(
                myAlert(
                    'Wystąpił błąd',
                    'Błąd połączenia z serwerem',
                    'Zamknij',
                    () => {
                        setAlertPlaceholder(null)
                    },
                    'error'
                )
            )
            return
        }

        if (/^[0-9a-fA-F]{24}$/.test(userid)) {
            setAlertPlaceholder(
                myAlert(
                    'Wystąpił błąd',
                    'Błąd połączenia z serwerem',
                    'Zamknij',
                    () => {
                        setAlertPlaceholder(null)
                    },
                    'error'
                )
            )
        }

        myFetch('/api/getBasicUserData', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                userid: userid,
            }),
        })
            .then(res => res.json())
            .then(res => {
                setUserData(res)
            })
            .catch(err => {
                setAlertPlaceholder(
                    myAlert(
                        'Wystąpił błąd',
                        'Błąd połączenia z serwerem',
                        'Zamknij',
                        () => {
                            setAlertPlaceholder(null)
                        },
                        'error'
                    )
                )
            })
    }, [userid])

    return { userData, alertPlaceholder }
}

export default useUserData
