import { useEffect, useRef, useState } from 'react'

import myAlert from '../../utils/AlertProvider'
import myFetch from '../../utils/myFetch'
import { useNavigate } from 'react-router-dom'
import './newSet.scss'
import { Button, FormControlLabel, IconButton, MenuItem, Select, Switch, TextField, Tooltip, useTheme } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import DeleteIcon from '@mui/icons-material/Delete'
import TranslateIcon from '@mui/icons-material/Translate'

const NewSet = () => {
    const userid = localStorage.getItem('loggedUserID')
    const navigate = useNavigate()
    const theme = useTheme()
    const ref = useRef()
    const titleRef = useRef()
    const tabRef = useRef()

    const [alertPlaceholder, setAlertPlaceholder] = useState(null)
    const [listFiszki, setListFiszki] = useState()
    const [arrayFiszki, setArrayFiszki] = useState([])
    const [setName, setSetName] = useState('NOWY ZESTAW FISZEK')
    const [kategoria, setKategoria] = useState(1)

    const [fiszkaStatus, setFiszkaStatus] = useState(false)
    const [animationValue, setAnimationValue] = useState('none')
    const animationStarter = 'obrotFiszki 0.5s linear'

    const [copilot, setCopilot] = useState(false)

    const [text1, setText1] = useState('')
    const [text2, setText2] = useState('')

    const [lettersOpen, setLettersOpen] = useState(false)

    useEffect(() => {
        if (userid == null) {
            setAlertPlaceholder(
                myAlert(
                    'Wystąpił błąd',
                    'Musisz być zalogowany, aby móc dodawać własne zestawy',
                    'Powrót',
                    () => {
                        setAlertPlaceholder(null)
                        navigate('/')
                    },
                    'error'
                )
            )
        }
    })

    const deleteFromList = i => {
        let temp = arrayFiszki.splice(i, 1)
        setArrayFiszki(temp)
        generateList(temp)
    }

    const addToList = () => {
        if (text1 == '' && text2 == '') {
            setAlertPlaceholder(
                myAlert(
                    'Wystąpił błąd',
                    'Fiszka jest pusta z obydwu stron',
                    'Zamknij',
                    () => {
                        setAlertPlaceholder(null)
                    },
                    'error'
                )
            )
            return
        }

        let temp = [...arrayFiszki, { side1: text1, side2: text2 }]
        setArrayFiszki(temp)
        generateList(temp)
        setText1('')
        setText2('')
        if (fiszkaStatus) {
            changeFiszkaStatus()
            setTimeout(() => {
                ref.current.focus()
            }, 500)
        } else {
            ref.current.focus()
        }
    }

    const generateList = temp => {
        setListFiszki(
            temp.map((element, i) => (
                <li>
                    <span>{element.side1}</span>
                    <span>{element.side2}</span>
                    <Tooltip title='Usuń z zestawu'>
                        <IconButton
                            onClick={() => {
                                deleteFromList(i)
                            }}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </li>
            ))
        )
    }

    const zmienKategorie = e => {
        setKategoria(e.target.value)
    }

    const changeFiszkaStatus = async () => {
        setAnimationValue(animationStarter)
        setTimeout(() => {
            setFiszkaStatus(!fiszkaStatus)
        }, 250)
        setTimeout(() => {
            setAnimationValue('none')
        }, 500)
    }

    const checkTabPress = async e => {
        if (e.keyCode == 9) {
            changeFiszkaStatus()
            setTimeout(() => {
                tabRef.current.focus()
            }, 500)

            console.log(copilot)

            if (copilot) {
                await myFetch('/api/translate', {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        category: kategoria,
                        text: text1,
                    }),
                })
                    .then(res => res.json())
                    .then(res => {
                        console.log(res)
                        if (res.status == 0) {
                            setText2(res.translate)
                            //tabRef.current.select()
                        } else {
                            setAlertPlaceholder(
                                myAlert(
                                    'Wystąpił błąd',
                                    res.error,
                                    'Zamknij',
                                    () => {
                                        setAlertPlaceholder(null)
                                    },
                                    'error'
                                )
                            )
                        }
                    })
                    .catch(err => {
                        setAlertPlaceholder(
                            myAlert(
                                'Wystąpił błąd',
                                err,
                                'Zamknij',
                                () => {
                                    setAlertPlaceholder(null)
                                },
                                'error'
                            )
                        )
                    })
            }
        }
    }

    const saveSet = async () => {
        console.log(arrayFiszki)
        if (arrayFiszki.length < 1) {
            setAlertPlaceholder(
                myAlert(
                    'Wystąpił błąd',
                    'Zestaw fiszek jest pusty',
                    'Zamknij',
                    () => {
                        setAlertPlaceholder(null)
                    },
                    'error'
                )
            )
        } else {
            await myFetch('/api/saveSet', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    userid: userid,
                    arrayFiszki: arrayFiszki,
                    category: kategoria,
                    setName: setName,
                }),
            })
                .then(res => res.json())
                .then(res => {
                    if (res.status == 0) {
                        setAlertPlaceholder(
                            myAlert(
                                'Zapisano',
                                'Zestaw fiszek został zapisany',
                                'Zamknij',
                                () => {
                                    setAlertPlaceholder(null)
                                },
                                'success'
                            )
                        )
                    } else {
                        setAlertPlaceholder(
                            myAlert(
                                'Wystąpił błąd',
                                res.error,
                                'Zamknij',
                                () => {
                                    setAlertPlaceholder(null)
                                },
                                'error'
                            )
                        )
                    }
                })
        }
    }

    const handleLetterButton = () => {
        setLettersOpen(!lettersOpen)
    }

    const addLeter = letter => {
        if (fiszkaStatus) {
            setText2(text2 + letter)
            tabRef.current.focus()
        } else {
            setText1(text1 + letter)
            ref.current.focus()
        }
    }

    return (
        <main className='mainNewSet' style={{ backgroundColor: theme.palette.background.default, color: theme.palette.font.color }}>
            <header>
                <TextField onClick={() => titleRef.current.select()} inputRef={titleRef} inputProps={{ min: 0, style: { textAlign: 'center' } }} value={setName} onChange={e => setSetName(e.target.value)} variant='standard' />
            </header>
            <div>
                <div className='buttonsBox'>
                    <Tooltip title='Dodaj do zestawu'>
                        <IconButton onClick={addToList} size='large' color='primary'>
                            <AddBoxOutlinedIcon fontSize='large' />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Zapisz zestaw fiszek'>
                        <IconButton onClick={saveSet} size='large' color='success'>
                            <SaveIcon fontSize='large' />
                        </IconButton>
                    </Tooltip>
                    <Select value={kategoria} onChange={zmienKategorie}>
                        <MenuItem value={1}>Angielski</MenuItem>
                        <MenuItem value={2}>Niemiecki</MenuItem>
                        <MenuItem value={3}>Inne</MenuItem>
                    </Select>
                    {kategoria != 3 ? (
                        <FormControlLabel
                            style={{ marginLeft: 15 }}
                            control={
                                <Tooltip title='Copilot zaproponuje tłumaczenia na drugiej stronie fiszki'>
                                    <Switch checked={copilot} onChange={e => setCopilot(e.target.checked)} color='secondary' />
                                </Tooltip>
                            }
                            label='Copilot'
                        />
                    ) : null}
                    <Tooltip title={lettersOpen ? 'Ukryj panel ze znakami specjalnymi' : 'Pokaż panel ze znakami specjalnymi'}>
                        <IconButton onClick={handleLetterButton} size='large' color='secondary'>
                            <TranslateIcon fontSize='large' />
                        </IconButton>
                    </Tooltip>
                </div>
                <section className='midtemp'>
                    <div className='mid'>{lettersOpen ? <section className='lettersContainer'></section> : null}</div>
                    <article
                        style={{
                            borderColor: fiszkaStatus ? theme.palette.secondary.main : theme.palette.primary.main,
                            animation: animationValue,
                        }}>
                        <header style={{ color: fiszkaStatus ? theme.palette.secondary.main : theme.palette.primary.main }}>
                            {fiszkaStatus ? '2 strona' : '1 strona'}
                            <Tooltip title='Obróć fiszkę'>
                                <IconButton onClick={changeFiszkaStatus} color={fiszkaStatus ? 'secondary' : 'primary'}>
                                    {fiszkaStatus ? <RotateLeftIcon /> : <RotateRightIcon />}
                                </IconButton>
                            </Tooltip>
                        </header>
                        <div>
                            {fiszkaStatus ? (
                                <TextField placeholder='Wpisz tutaj treść' multiline maxRows={4} className='inputCard' variant='standard' color='secondary' onChange={e => setText2(e.target.value)} inputRef={tabRef} value={text2} />
                            ) : (
                                <TextField placeholder='Wpisz tutaj treść' multiline maxRows={4} className='inputCard' variant='standard' onChange={e => setText1(e.target.value)} value={text1} inputRef={ref} onKeyDown={checkTabPress} />
                            )}
                        </div>
                    </article>
                    <div className='mid'>
                        {' '}
                        {lettersOpen ? (
                            <section className='lettersContainer'>
                                <Button onClick={() => addLeter('ä')} variant='contained' style={{ textTransform: 'none' }}>
                                    ä
                                </Button>
                                <Button onClick={() => addLeter('ö')} color='secondary' variant='contained' style={{ textTransform: 'none' }}>
                                    ö
                                </Button>
                                <Button onClick={() => addLeter('ü')} variant='contained' style={{ textTransform: 'none' }}>
                                    ü
                                </Button>
                                <Button onClick={() => addLeter('Ä')} color='secondary' variant='contained' style={{ textTransform: 'none' }}>
                                    Ä
                                </Button>
                                <Button onClick={() => addLeter('Ö')} variant='contained' style={{ textTransform: 'none' }}>
                                    Ö
                                </Button>
                                <Button onClick={() => addLeter('Ü')} color='secondary' variant='contained' style={{ textTransform: 'none' }}>
                                    Ü
                                </Button>
                                <Button onClick={() => addLeter('ß')} variant='contained' style={{ textTransform: 'none' }}>
                                    ß
                                </Button>
                            </section>
                        ) : null}
                    </div>
                </section>
                <aside>
                    <header>Lista fiszek</header>
                    <ul>{listFiszki}</ul>
                </aside>
            </div>
            {alertPlaceholder}
        </main>
    )
}

export default NewSet
