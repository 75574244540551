import { useEffect, useRef, useState } from 'react'
import myFetch from '../../utils/myFetch'
import { useParams } from 'react-router-dom'
import { Button, Input, TextField, useTheme, Backdrop, CircularProgress } from '@mui/material'

import './writeTest.scss'
import Navbar from '../../utils/Navbar/Navbar'
import Result from './Result'

const WriteTest = () => {
    let { setID } = useParams()
    const theme = useTheme()

    const [test, setTest] = useState([])
    const [testElements, setTestElemets] = useState([])
    const [odpowiedzi, setOdpowiedzi] = useState([])
    const [setName, setSetName] = useState('')
    const [category, setCategory] = useState(0)
    const [setIcon, setSetIcon] = useState(require('../../assets/images/fishki_nobg_sm.png'))
    const [errorInfo, setErrorInfo] = useState('Trwa ładowanie')
    const [liczbaPunktow, setLiczbaPunktow] = useState(0)
    const [isEnded, setIsEnded] = useState(false)

    const [alertPlaceholder, setAlertPlaceHolder] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getTest()
        getSetData()
    }, [])

    const getSetData = async () => {
        await myFetch('/api/getSetData', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ setID: setID }),
        })
            .then(res => res.json())
            .then(res => {
                setSetName(res.name)
                if (res.category == 1) {
                    setSetIcon(require('../../assets/images/english.png'))
                    setCategory('Angielski')
                } else if (res.category == 2) {
                    setSetIcon(require('../../assets/images/german.png'))
                    setCategory('Niemiecki')
                } else {
                    setSetIcon(require('../../assets/images/fishki_nobg_sm.png'))
                    setCategory('Inne')
                }
            })
    }

    const getTest = async () => {
        setLoading(true)
        await myFetch('/api/generateTest', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ setID: setID }),
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    setErrorInfo(
                        'Nie udało się utworzyć testu. \
                    Nie można tworzyć testów z zestawów składających się z mniej niż 4 fiszek. \
                    Jeśli ten ma więcej, to po prostu jakiś błąd i spróbuj później :))'
                    )
                    return
                } else {
                    for (let i = 0; i < res.length; i++) {
                        res[i].odpowiedz = String(Math.floor(Math.random() * 10000))
                    }
                    let temp = []
                    temp.length = res.length
                    setOdpowiedzi(temp)
                    setTest(res)
                    setLoading(false)
                }
            })
    }

    const dodajOdpowiedz = (i, wartosc) => {
        let temp = odpowiedzi
        temp[i] = wartosc
        setOdpowiedzi(temp)

        setTest(
            test.map((element, index) => {
                if (index == i) {
                    element.odpowiedz = String(Math.floor(Math.random() * 10000))
                }
                return element
            })
        )

        console.log(odpowiedzi)
    }

    const aktualizujTextField = (i, wartosc) => {
        let temp = odpowiedzi
        temp[i] = wartosc
        setOdpowiedzi(temp)
    }

    const sprawdzOdpowiedzi = () => {
        window.scrollTo(0, 0)
        let punkty = 0
        for (let i = 0; i < test.length; i++) {
            if (odpowiedzi[i] === test[i].zawartosc.prawidlowaOpcja) {
                punkty++
                console.log(test[i].zawartosc.pytanie + ' jest poprawne ')
            } else {
                console.log(test[i].zawartosc.prawidlowaOpcja)
            }
        }
        setLiczbaPunktow(punkty)
        setIsEnded(true)
    }

    return (
        <>
            <Navbar />
            <div style={{ backgroundColor: theme.palette.background.default, color: theme.palette.font.color }} className='writeTestMain'>
                {!isEnded ? (
                    <>
                        <header>
                            <h1>
                                Test: <span style={{ color: theme.palette.primary.main }}>{setName}</span>
                            </h1>
                            <p>Ilość pytań: {test.length}</p>
                        </header>
                        {test.length > 0 ? (
                            test.map((element, i) => {
                                if (element.typ == 'trueFalse') {
                                    return (
                                        <div key={element.odpowiedz} className='prawdaFalsz'>
                                            <header>{element.zawartosc.pytanie}</header>
                                            <div>
                                                <Button disabled={odpowiedzi[i] === true ? true : false} onClick={() => dodajOdpowiedz(i, true)} variant='contained'>
                                                    Prawda
                                                </Button>
                                                <Button disabled={odpowiedzi[i] === false ? true : false} onClick={() => dodajOdpowiedz(i, false)} color='secondary' variant='contained'>
                                                    Fałsz
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                } else if (element.typ == 'abcd') {
                                    return (
                                        <div key={element.odpowiedz} className='abcd'>
                                            <header>{element.zawartosc.pytanie}</header>
                                            <div>
                                                <div>
                                                    <Button disabled={odpowiedzi[i] === 0 ? true : false} onClick={() => dodajOdpowiedz(i, 0)} variant='contained'>
                                                        {element.zawartosc.odpowiedzi[0]}
                                                    </Button>
                                                    <Button disabled={odpowiedzi[i] === 1 ? true : false} onClick={() => dodajOdpowiedz(i, 1)} color='secondary' variant='contained'>
                                                        {element.zawartosc.odpowiedzi[1]}
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button disabled={odpowiedzi[i] === 2 ? true : false} onClick={() => dodajOdpowiedz(i, 2)} color='secondary' variant='contained'>
                                                        {element.zawartosc.odpowiedzi[2]}
                                                    </Button>
                                                    <Button disabled={odpowiedzi[i] === 3 ? true : false} onClick={() => dodajOdpowiedz(i, 3)} variant='contained'>
                                                        {element.zawartosc.odpowiedzi[3]}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else if (element.typ == 'writing') {
                                    return (
                                        <div key={element.odpowiedz} className='writing'>
                                            <header>{element.zawartosc.pytanie}</header>
                                            <TextField
                                                value={odpowiedzi[i]}
                                                onChange={e => {
                                                    aktualizujTextField(i, e.target.value)
                                                }}
                                                variant='standard'
                                                label='tłumaczenie'
                                            />
                                        </div>
                                    )
                                }
                            })
                        ) : (
                            <h3> {errorInfo} </h3>
                        )}
                        <footer style={{ backgroundColor: theme.palette.background.paper }}>
                            <Button onClick={sprawdzOdpowiedzi} variant='contained'>
                                Sprawdź odpowiedzi
                            </Button>
                            <Button variant='contained' disabled>
                                Kopiuj test
                            </Button>
                        </footer>
                    </>
                ) : (
                    <Result test={test} userRes={odpowiedzi} loadSetter={setLoading} points={liczbaPunktow} maxPoints={test.length} />
                )}
            </div>
            <Backdrop open={loading} sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress color='inherit' />
            </Backdrop>
            {alertPlaceholder}
        </>
    )
}

export default WriteTest
