import { Tooltip, useTheme } from '@mui/material'
import './itIsBeta.scss'
import { useEffect, useState } from 'react'
import myFetch from '../../utils/myFetch'
import myAlert from '../../utils/AlertProvider'
import { useNavigate } from 'react-router-dom'

const ItIsBeta = ({ setAlertPlaceholder }) => {
    const theme = useTheme()

    const navigate = useNavigate()

    return (
        <section className='newSetsSectionContainer'>
            <header style={{ color: theme.palette.primary.main }}>Ważne!</header>
            <p>
                <div>
                    Obecnie aplikacja jest w <span style={{ color: theme.palette.success.main }}> fazie testów</span> <br></br>
                    Brakuje kilku funkcji, kilka pewnie też nie działa tak jak byśmy chcieli <br></br>
                    Więcej dowiesz się{' '}
                    <a style={{ color: theme.palette.primary.main }} href='/roadmap'>
                        tu
                    </a>
                </div>
            </p>
        </section>
    )
}

export default ItIsBeta
