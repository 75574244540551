const myFetch = async (url, options = {}) => {
    if (window.location.hostname === 'localhost') {
        //localhost dev
        return await fetch('https://localhost:3001' + url, options)
    } else if (window.location.host === 'olipop.pl:19006') {
        //server master
        return await fetch('https://olipop.pl:3000' + url, options)
    } else {
        //build
        return await fetch('https://fishki.rurbanski.pl' + url, options)
    }
}

export default myFetch
