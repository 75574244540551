import { useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

const Result = ({ points, maxPoints, loadSetter, test, userRes }) => {
    const [zdjecieLewo, setZdjecieLewo] = useState(require('../../assets/images/deadInside.jpg'))
    const [zdjeciePrawo, setZdjeciePrawo] = useState(require('../../assets/images/uncanny.jpg'))
    const [tekst, setTekst] = useState('Mogło być lepiej ziom ;-;')
    const [procent, setProcent] = useState(0)
    const theme = useTheme()
    useEffect(() => {
        loadSetter(true)

        let procent = (Math.round((points / maxPoints) * 100) * 100) / 100
        setProcent(procent)
        if (procent >= 40 && procent < 55) {
            setTekst('Zdałeś żołnierzu, ale mogło być lepiej')
            setZdjeciePrawo(require('../../assets/images/canny.webp'))
            setZdjecieLewo(require('../../assets/images/ronaldo.jpg'))
        } else if (procent >= 55 && procent < 70) {
            setTekst('Trójeczka ;DD \n\n Będzie obiad w domu ')
            setZdjeciePrawo(require('../../assets/images/moreCanny.webp'))
            setZdjecieLewo(require('../../assets/images/zupa.jpg'))
        } else if (procent >= 70 && procent < 85) {
            setZdjecieLewo(require('../../assets/images/betoniarz.png'))
            setZdjeciePrawo(require('../../assets/images/2moreCanny.webp'))
            setTekst('CZTERY!')
        } else if (procent >= 85 && procent < 100) {
            setTekst('Piąteczka\nCzłowiek sukcesu')
            setZdjecieLewo(require('../../assets/images/shelby.JPG'))
            setZdjeciePrawo(require('../../assets/images/3moreCanny.webp'))
        } else if (procent == 100) {
            setTekst('100%\nChowaj te sciągi ziom')
            setZdjeciePrawo(require('../../assets/images/god.webp'))
            setZdjecieLewo(require('../../assets/images/chad.webp'))
        }
        loadSetter(false)
    }, [])

    return (
        <>
            <header>
                <h1>
                    Masz {points} punktów z {maxPoints} możliwych
                </h1>
                <p>Uzyskałeś wynik {procent}%</p>
            </header>
            <section className='resultMain'>
                <img src={zdjecieLewo} />
                <p>{tekst}</p>
                <img src={zdjeciePrawo} />
            </section>
            <section className='resultsList'>
                <header>Wyniki poszczególnych pytań poniżej</header>
                <table border={1}>
                    <thead>
                        <th>Pytanie</th>
                        <th>Twoja odpowiedź</th>
                        <th>Poprawna odpowiedź</th>
                    </thead>
                    <tbody>
                        {test.length > 0
                            ? test.map((element, i) => (
                                  <tr>
                                      <td>{element.zawartosc.pytanie}</td>
                                      <td style={{ color: element.zawartosc.prawidlowaOpcja === userRes[i] ? theme.palette.success.main : theme.palette.error.main }}>
                                          {element.typ == 'writing'
                                              ? userRes[i]
                                                  ? userRes[i]
                                                  : 'Nie udzielono odpowiedzi'
                                              : element.typ == 'abcd'
                                              ? userRes[i]
                                                  ? element.zawartosc.odpowiedzi[userRes[i]]
                                                  : 'Nie udzielono odpowiedzi'
                                              : userRes[i] === true
                                              ? 'Prawda'
                                              : userRes[i] === false
                                              ? 'Fałsz'
                                              : 'Nie udzielono odpowiedzi'}
                                      </td>
                                      <td style={{ color: element.zawartosc.prawidlowaOpcja === userRes[i] ? theme.palette.success.main : theme.palette.error.main }}>
                                          {element.typ == 'writing' ? element.zawartosc.prawidlowaOpcja : element.typ == 'abcd' ? element.zawartosc.odpowiedzi[element.zawartosc.prawidlowaOpcja] : element.zawartosc.prawidlowaOpcja === true ? 'Prawda' : 'Fałsz'}
                                      </td>
                                  </tr>
                              ))
                            : null}
                    </tbody>
                </table>
            </section>
        </>
    )
}

export default Result
