import { useTheme } from '@mui/material'

import './writeSet.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import myFetch from '../../utils/myFetch'
import { Button, FormControlLabel, IconButton, MenuItem, Select, Switch, TextField, Tooltip } from '@mui/material'
import myAlert from '../../utils/AlertProvider'
import VerifiedIcon from '@mui/icons-material/Verified'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CheckIcon from '@mui/icons-material/Check'
import Navbar from '../../utils/Navbar/Navbar'

const WriteSet = () => {
    const theme = useTheme()

    const textRef = useRef()

    const navigate = useNavigate()

    let { setID } = useParams()

    const [setName, setSetName] = useState('')
    const [arrayFiszki, setArrayFiszki] = useState([{ side1: '', side2: '' }])
    const [defaultArray, setDefaultArray] = useState([])
    const [setIcon, setSetIcon] = useState(require('../../assets/images/fishki_nobg_sm.png'))
    const [category, setCategory] = useState('Inne')
    const [alertPlaceholder, setAlertPlaceholder] = useState(null)
    const [isByAdmins, setIsByAdmins] = useState(false)
    const [actualPosition, setActualPosition] = useState(0)
    const [text, setText] = useState('')
    const [animationValue, setAnimationValue] = useState('none')
    const [doPoprawy, setDoPoprawy] = useState([])
    const [inputLabel, setInputLabel] = useState('')
    const [inputError, setInputError] = useState(false)
    const [finishedSection, setFinishedSection] = useState(null)
    const nextPisanieStarter = 'nastepnePisanie 0.5s linear'

    useEffect(() => {
        getSetData()
    }, [])

    const getSetData = async () => {
        await myFetch('/api/getSetData', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ setID: setID }),
        })
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setSetName(res.name)
                setArrayFiszki(res.fiszki)
                setDefaultArray(res.fiszki)
                setIsByAdmins(res.isByAdmins)
            })
    }

    const addLeter = leter => {
        setText(text + leter)
        textRef.current.focus()
    }

    const goBack = () => {
        navigate(-1)
    }

    const restart = () => {
        setActualPosition(0)
        setArrayFiszki(defaultArray)
        setDoPoprawy([])
        setText('')
        setInputError(false)
        setInputLabel('')
        setFinishedSection(null)
    }

    const nastepnaFiszka = () => {
        if (actualPosition < arrayFiszki.length - 1) {
            setAnimationValue(nextPisanieStarter)
            setTimeout(() => {
                setActualPosition(actualPosition + 1)
                setText('')
                setInputError(false)
                setInputLabel('')
            }, 250)
            setTimeout(() => {
                setAnimationValue('none')
            }, 500)
        } else {
            if (doPoprawy.length > 0) {
                setActualPosition(0)
                setArrayFiszki(doPoprawy)
                setDoPoprawy([])
                setText('')
                setInputError(false)
                setInputLabel('')
            } else {
                setFinishedSection(
                    <section className='finishedSection'>
                        <header style={{ color: theme.palette.secondary.main }}>KONIEC</header>
                        <div>
                            <img src={require('../../assets/images/fajen1.png')} />
                            <div style={{ color: theme.palette.primary.main }}>UDALO CI SEI</div>
                            <img src={require('../../assets/images/fajen2.png')} />
                        </div>
                        <div>
                            <Button onClick={goBack} variant='contained'>
                                Powrót
                            </Button>
                            <Button onClick={restart} variant='contained'>
                                Uruchom ponownie
                            </Button>
                        </div>
                    </section>
                )
            }
        }
    }

    const checkInput = () => {
        if (text == arrayFiszki[actualPosition].side2) {
            nastepnaFiszka()
        } else {
            setInputError(true)
            if (doPoprawy.indexOf(arrayFiszki[actualPosition]) == -1) {
                setDoPoprawy([...doPoprawy, arrayFiszki[actualPosition]])
                setInputLabel(arrayFiszki[actualPosition].side2)
            }
        }
    }

    const updateInput = e => {
        let newText = e.target.value
        setText(newText)
        if (newText == arrayFiszki[actualPosition].side2) {
            nastepnaFiszka()
        }
    }

    return (
        <>
            <Navbar />
            <div style={{ backgroundColor: theme.palette.background.default, color: theme.palette.font.color }} className='mainWriteSet'>
                <header>
                    {setName}{' '}
                    {isByAdmins ? (
                        <Tooltip title='Zestaw tworzony przed administratorów'>
                            <VerifiedIcon fontSize='large' color='secondary' />
                        </Tooltip>
                    ) : null}
                </header>
                <article style={{ animation: animationValue }}>
                    <header>{arrayFiszki[actualPosition].side1}</header>
                    <div>
                        <div className='horizontalContainer'>
                            <TextField error={inputError} inputRef={textRef} value={text} onChange={updateInput} variant='standard' />
                            <IconButton onClick={checkInput} color='primary' size='large'>
                                <CheckIcon fontSize='inherit' />
                            </IconButton>
                        </div>
                        <span>{inputLabel}</span>
                        <section className='lettersContainer'>
                            <Button onClick={() => addLeter('ä')} variant='contained' style={{ textTransform: 'none' }}>
                                ä
                            </Button>
                            <Button onClick={() => addLeter('ö')} color='secondary' variant='contained' style={{ textTransform: 'none' }}>
                                ö
                            </Button>
                            <Button onClick={() => addLeter('ü')} variant='contained' style={{ textTransform: 'none' }}>
                                ü
                            </Button>
                            <Button onClick={() => addLeter('Ä')} color='secondary' variant='contained' style={{ textTransform: 'none' }}>
                                Ä
                            </Button>
                            <Button onClick={() => addLeter('Ö')} variant='contained' style={{ textTransform: 'none' }}>
                                Ö
                            </Button>
                            <Button onClick={() => addLeter('Ü')} color='secondary' variant='contained' style={{ textTransform: 'none' }}>
                                Ü
                            </Button>
                            <Button onClick={() => addLeter('ß')} variant='contained' style={{ textTransform: 'none' }}>
                                ß
                            </Button>
                        </section>
                    </div>
                    <footer>{actualPosition + 1 + ' / ' + arrayFiszki.length}</footer>
                </article>
                {finishedSection}
                {alertPlaceholder}
            </div>
        </>
    )
}

export default WriteSet
