import './notFound.scss'

import rybka from '../../assets/images/rybka.png'

const NotFound = () => {
    return (
        <main>
            <section id='notFound' className='container'>
                <img src={rybka} alt='rybka' />
                <h1>404</h1>
                <h2>Nie znaleziono strony</h2>
            </section>
        </main>
    )
}

export default NotFound
