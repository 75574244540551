import { Button } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

import GoogleIcon from '@mui/icons-material/Google'

//smieszny kodzik znaleziony na stackoverflow
//obsluguje on tworzenie i dzialanie przycisku do logowania z google

const loadScript = src =>
    new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) return resolve()
        const script = document.createElement('script')
        script.src = src
        script.onload = () => resolve()
        script.onerror = err => reject(err)
        document.body.appendChild(script)
    })
//} nie mozna dodac klamr do funkcji powyzej bo sie wysypie

const GoogleAuth = ({ callback }) => {
    const googleButton = useRef(null)

    const [przycisk, setPrzycisk] = useState(<div></div>)

    useEffect(() => {
        const src = 'https://accounts.google.com/gsi/client'
        const id = '744222178774-c04mti347ukmiqmdl57bag26l2bk7fjq.apps.googleusercontent.com'

        loadScript(src)
            .then(() => {
                /*global google*/
                console.log(google)
                google.accounts.id.initialize({
                    client_id: id,
                    callback: handleCredentialResponse,
                })
                setPrzycisk(
                    <Button onClick={() => google.accounts.id.prompt()} variant='contained' color='google' startIcon={<GoogleIcon />}>
                        Google
                    </Button>
                )
                //google.accounts.id.renderButton(googleButton.current, { theme: 'outline', size: 'large' })
            })
            .catch(console.error)

        return () => {
            const scriptTag = document.querySelector(`script[src="${src}"]`)
            if (scriptTag) document.body.removeChild(scriptTag)
        }
    }, [])

    function handleCredentialResponse(response) {
        console.log('Encoded JWT ID token: ' + response.credential)
        let decodedData = decodeJwtResponse(response.credential)
        //console.log(`Decoded data: {look at next log}`) do debugowania
        //console.log(decodedData)
        callback(decodedData)
    }

    function decodeJwtResponse(token) {
        var base64Url = token.split('.')[1]
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                })
                .join('')
        )

        return JSON.parse(jsonPayload)
    }

    const handlePrompt = notification => {
        if (notification.isNotDisplayed()) {
        } else {
            console.log('Ala ma kota')
        }
    }

    //return <div ref={googleButton}></div>
    return przycisk
}

export default GoogleAuth
