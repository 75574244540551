import Navbar from '../../utils/Navbar/Navbar'
import './profile.scss'

import useUserData from '../../hooks/useUserData'
import myFetch from '../../utils/myFetch'
import myAlert from '../../utils/AlertProvider'

import { useNavigate, useParams } from 'react-router-dom'

import { useTheme } from '@mui/material'

import { Settings } from '@mui/icons-material'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { useEffect, useState } from 'react'

const Profile = () => {
    const { userID } = useParams()

    const theme = useTheme()
    const navigate = useNavigate()

    const actualUserId = localStorage.getItem('loggedUserID')
    const userid = userID
    const { userData, errorAlert } = useUserData(userid)

    const [yourSets, setYourSets] = useState(null)
    const [yourSetsArr, setYourSetsArr] = useState([])
    const [likedSets, setLikedSets] = useState(null)
    const [alertPlaceholder, setAlertPlaceholder] = useState(null)

    const link = window.location.hostname === 'localhost' ? 'https://localhost:3001/' : 'https:/fishki.rurbanski.pl/'

    useEffect(() => {
        getCounts()
        getYourSets()
        if (errorAlert != null) {
            setAlertPlaceholder(errorAlert)
        }
    }, [])

    const getCounts = async () => {
        await myFetch('/api/getSetsCount', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                userid: userid,
            }),
        })
            .then(res => res.json())
            .then(res => {
                const [your, liked] = res
                setYourSets(your)
                setLikedSets(liked)
            })
            .catch(err => {
                setAlertPlaceholder(
                    myAlert(
                        'Wystąpił błąd',
                        'Błąd połączenia z serwerem',
                        'Zamknij',
                        () => {
                            setAlertPlaceholder(null)
                        },
                        'error'
                    )
                )
            })
    }

    const getYourSets = async () => {
        await myFetch('/api/getYourSets', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                userid: userid,
            }),
        })
            .then(res => res.json())
            .then(res => {
                setYourSetsArr(res)
            })
            .catch(err => {
                setAlertPlaceholder(
                    myAlert(
                        'Wystąpił błąd',
                        'Błąd połączenia z serwerem',
                        'Zamknij',
                        () => {
                            setAlertPlaceholder(null)
                        },
                        'error'
                    )
                )
            })
    }

    const openSet = async setid => {
        await myFetch('/api/addToLastOpened', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userid: userid, setID: setid }),
        })
            .then(res => res.json())
            .then(res => {
                navigate('/openSet/' + setid)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const uploadPic = async event => {
        const form = new FormData()
        form.append('profilePic', event.target.files[0])
        form.append('userid', userid)

        await myFetch('/api/upload', {
            method: 'post',
            body: form,
        })
            .then(res => res.text())
            .then(res =>
                setAlertPlaceholder(
                    myAlert(
                        'Sukces',
                        res,
                        'Zamknij',
                        () => {
                            window.location.reload()
                            setAlertPlaceholder(null)
                        },
                        'success'
                    )
                )
            )
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <>
            <Navbar />
            <main id='profile-section' style={{ backgroundColor: theme.palette.background.default, color: theme.palette.font.color }}>
                <div className='container'>
                    <section id='user-info'>
                        <figure>
                            {userData && userData.photoPath.length > 0 ? <img src={link + userData?.photoPath} /> : <img src={`${link}/profile_pictures/user.png`} />}
                            {actualUserId === userid && (
                                <>
                                    <input type='file' id='profile-upload' style={{ display: 'none' }} onChange={e => uploadPic(e)} />
                                    <label htmlFor='profile-upload'>
                                        <CameraAltIcon />
                                    </label>
                                </>
                            )}
                        </figure>
                        <article>
                            <h2 className='nick'>{userData ? userData.nick : 'Nazwa użytkownika'}</h2>
                            <div className='fishki'>
                                <p>
                                    Liczba twoich zestawów: <span>{yourSets !== null ? yourSets : 0}</span>
                                </p>
                                <p>
                                    Liczba przerobionych zestawów: <span>{likedSets !== null ? likedSets : 0}</span>
                                </p>
                            </div>
                        </article>
                        <aside>
                            <Settings />
                        </aside>
                    </section>
                    <section id='your-sets'>
                        <h2>Twoje zestawy fiszek</h2>
                        <div className='inner-sets'>
                            {yourSetsArr.map(({ id, name, category }, index) => {
                                let photoPath
                                if (category === 1) {
                                    photoPath = require('../../assets/images/english.png')
                                } else if (category === 2) {
                                    photoPath = require('../../assets/images/german.png')
                                } else {
                                    photoPath = require('../../assets/images/fishki_nobg_sm.png')
                                }

                                return (
                                    <article key={index} className='one-set' onClick={() => openSet(id)}>
                                        <figure>
                                            <img src={photoPath} alt='German' />
                                        </figure>
                                        <aside>
                                            <h3>{name}</h3>
                                        </aside>
                                    </article>
                                )
                            })}
                        </div>
                    </section>
                </div>
            </main>
            {alertPlaceholder}
        </>
    )
}

export default Profile
