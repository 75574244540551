import { Tooltip, useTheme } from '@mui/material'
import './newSetsSection.scss'
import { useEffect, useState } from 'react'
import myFetch from '../../utils/myFetch'
import myAlert from '../../utils/AlertProvider'
import { useNavigate } from 'react-router-dom'

const LastSetsSection = ({ setAlertPlaceholder }) => {
    const theme = useTheme()

    const navigate = useNavigate()

    const [setsArray, setSetsArray] = useState([])
    const [sets, setSets] = useState(null)

    const userid = localStorage.getItem('loggedUserID')

    useEffect(() => {
        getNewSets()
    }, [])

    const openSet = async setID => {
        navigate('/openSet/' + setID)
    }

    const getNewSets = async () => {
        if (userid != null) {
            await myFetch('/api/getLastOpenedSets', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userid: userid }),
            })
                .then(res => res.json())
                .then(res => {
                    if (res.status == 0) {
                        setSetsArray(res.sets)
                        let temp = []
                        res.sets.forEach(element => {
                            let photoPath = ''
                            if (element.category == 1) {
                                photoPath = require('../../assets/images/english.png')
                            } else if (element.category == 2) {
                                photoPath = require('../../assets/images/german.png')
                            } else {
                                photoPath = require('../../assets/images/fishki_nobg_sm.png')
                            }
                            temp.push(
                                <div
                                    onClick={() => {
                                        openSet(element.id)
                                    }}
                                    className='setThumb'>
                                    <img src={photoPath} />
                                    <Tooltip title={element.name}>
                                        <header>{element.name}</header>
                                    </Tooltip>
                                </div>
                            )
                        })
                        setSets(temp)
                    } else {
                        setAlertPlaceholder(
                            myAlert(
                                'Wystąpił błąd',
                                res.error,
                                'Zamknij',
                                () => {
                                    setAlertPlaceholder(null)
                                },
                                'error'
                            )
                        )
                    }
                })
                .catch(err => {
                    console.log(err)
                    setAlertPlaceholder(
                        myAlert(
                            'Wystąpił błąd',
                            'Błąd połączenia z serwerem',
                            'Zamknij',
                            () => {
                                setAlertPlaceholder(null)
                            },
                            'error'
                        )
                    )
                })
        } else {
            return
        }
    }

    return (
        <section style={{ marginBottom: 50 }} className='newSetsSectionContainer'>
            <header style={{ color: theme.palette.primary.main }}>Ostatnio przeglądane</header>
            {userid == null ? <span>Musisz być zalogowany, aby widzieć ostatnio przeglądane zestawy</span> : <article>{sets}</article>}
        </section>
    )
}

export default LastSetsSection

/*

                <div className='setThumb'>
                    <img src={require('../../assets/images/english.png')} />
                    <header>Nazwa zestawu</header>
                </div>
                <div className='setThumb'>
                    <img src={require('../../assets/images/german.png')} />
                    <header>Nazwa zestawu 2</header>
                </div>*/
